body {
  margin: 0;
  font-family: "MetropolisNF", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(69, 128, 255); */
  /* background: linear-gradient( */
  /* 180deg, */
  /* rgba(69, 128, 255, 1) 0%, */
  /* rgba(28, 232, 212, 1) 100% */
  /* ); */
}

code {
  font-family: MetropolisNF, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
