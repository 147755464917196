.fontSize {
  margin-top: 20px;
  padding: 0.5em;
}
p,
h1 {
  font-family: "Open Sans";
  transition: 0.4s;
  transition-timing-function: ease-in-out;
}

h1 {
  font-size: 35px;
}

.container {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

button {
  margin-top: 8vh;
  width: 80px;
  height: 80px;
  background: #000000;
  color: #ffffff;
  margin-right: 50px;
  user-select: none;
  cursor: pointer;
}
