/* * {
  cursor: none;
  background-color: #333;
} */

/* .cursor-dot, */
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: #ffd000 2px solid;
  border-radius: 10%;

  /* opacity: 1; */
  /* transition: opacity 0.8s ease-in-out, transform 0.3s ease-in-out; */
}

/* .cursor-dot {
  width: 10px;
  height: 10px;

  background-color: #ffd000;
} */

.cursor-dot-outline {
  width: 800px;
  height: 15px;
  background-color: hwb(0 0% 100% / 0.9);
  border-radius: 10%;

  /* color: #000; */
}

.links-container {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
}
